import React, {Component} from 'react';
import {Badge, Nav, NavItem, NavLink as RsNavLink} from 'reactstrap';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">

      </footer>
    )
  }
}

export default Footer;
