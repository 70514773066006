import React, {Component} from 'react';
import {
    Badge,
    Row,
    Col,
    Progress,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Button,
    ButtonToolbar,
    ButtonGroup,
    ButtonDropdown,
    Label,
    Input,
    Table
  } from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n'
import apisActions from '@/actions/ApisActions';
import {convertDate} from '@/helpers/convertDate';
import * as types from '@/constants/actionType';
import SwaggerUI from '@/views/Base/SwaggerUI/SwaggerUI';
import { type } from 'os';
import moment from 'moment';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          apis: {}
        }
    }

    componentWillMount() {//auto load luôn khi load brower
        this.props.onLoad()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.apis != this.props.apis) {
            this.setState({
              apis: nextProps.apis.data.data
            })
        }
    }

    render() {
        const { apis } = this.state
        let idAPI = apis.id;
        let firstChar = apis.name ? apis.name.charAt(0).toUpperCase() : null;
        let url = apis.thumbnailUrl ? `${process.env.WSO2_STORE}/api/am/store/v0.13${apis.thumbnailUrl}?w=200` : null;
        let hasThumb = {
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover'
        };

        return (
            <div className="detail-api animated fadeIn">
                <Button className="btn-outline-primary pull-right"
                        style={{marginRight: '2vh'}}
                        onClick={()=>this.props.history.goBack()}>
                        <i className="icon-arrow-left"></i> Back
                </Button>
                <h4 style={{marginLeft: '2vh'}}>{`${apis.name ? apis.name : ""}`}</h4>

                <hr/>
                <Row className="info-api">
                    <Col md={4} lg={3} xl={2}>
                        <div className="image-api">
                            <div className="image-post img-responsive img-thumbnail" style={url ? hasThumb : {fontSize: 70}}>
                                {url ? '' : firstChar}
                            </div>
                        </div>
                    </Col>
                    <Col md={8} lg={9} xs={12}>
                        <div className="info-detail-api">
                            <div>
                                <strong>Version</strong>: <span>{apis.version}</span>
                            </div>
                            <div>
                                <strong>By</strong>: <span>{apis.provider}</span>
                            </div>
                            <div>
                                <strong>Update</strong>:
                                <span id="date-time">{moment(apis.lastUpdatedTime).format("YYYY-MM-DD H:mm:ss")}</span>
                            </div>
                            <div>
                                <strong>Description</strong>: <span dangerouslySetInnerHTML={{ __html: apis.description ? apis.description.replace(/\n/g, "<br/>") : ''}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <hr/>
                {idAPI ? (
                    <SwaggerUI idAPI={idAPI}/>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        apis : state.apis,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoad: (params) => {
            dispatch(apisActions.actGetDetailAPI(params));
		},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
