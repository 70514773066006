import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { i18nReducer } from 'react-redux-i18n';
import { persistReducer } from 'redux-persist'
import {persistConfig} from '@/constants/persist'
import categories from './categories';
import apis from './apis';
import videos from './videos';

const rootReducer = combineReducers({
	form: formReducer,
	i18n: i18nReducer,
    categories,
    apis,
    videos
});

const persistedReducer = persistReducer(persistConfig, rootReducer)
export default persistedReducer;
