export const sidebar = {
    system : {
        value: 'Public API',
        store_link : 'Store'
    },
    video : {
        value: 'Video'
    },
    categories: {
        value: 'Categories',
        detail: 'API List'
    },
    apis: {
        value: 'API Detail'
    },
}
