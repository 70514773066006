import React, {Component} from 'react';
import { hot } from 'react-hot-loader'

import {Switch, Route, Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import Header from '../../components/Header/';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb/';
import Aside from '../../components/Aside/';
import Footer from '../../components/Footer/';
import ErrorBoundary from '../../components/ErrorBoundary/';
import Loadable from 'react-loading-overlay';

/* import Dashboard from '../../views/Dashboard/';

import Colors from '../../views/Theme/Colors/';
import Typography from '../../views/Theme/Typography/';

import Charts from '../../views/Charts/';
import Widgets from '../../views/Widgets/';

// Base
import Cards from '../../views/Base/Cards/';
import Forms from '../../views/Base/Forms/';
import Switches from '../../views/Base/Switches/';
import Tables from '../../views/Base/Tables/';
import Tabs from '../../views/Base/Tabs/';
import Breadcrumbs from '../../views/Base/Breadcrumbs/';
import Carousels from '../../views/Base/Carousels/';
import Collapses from '../../views/Base/Collapses/';
import Dropdowns from '../../views/Base/Dropdowns/';
import Jumbotrons from '../../views/Base/Jumbotrons/';
import ListGroups from '../../views/Base/ListGroups/';
import Navbars from '../../views/Base/Navbars/';
import Navs from '../../views/Base/Navs/';
import Paginations from '../../views/Base/Paginations/';
import Popovers from '../../views/Base/Popovers/';
import ProgressBar from '../../views/Base/ProgressBar/';
import Tooltips from '../../views/Base/Tooltips/';

// Buttons
import Buttons from '../../views/Buttons/Buttons/';
import ButtonDropdowns from '../../views/Buttons/ButtonDropdowns/';
import ButtonGroups from '../../views/Buttons/ButtonGroups/';
import SocialButtons from '../../views/Buttons/SocialButtons/';

// Icons
import Flags from '../../views/Icons/Flags/';
import FontAwesome from '../../views/Icons/FontAwesome/';
import SimpleLineIcons from '../../views/Icons/SimpleLineIcons/';

// Notifications
import Alerts from '../../views/Notifications/Alerts/';
import Badges from '../../views/Notifications/Badges/';
import Modals from '../../views/Notifications/Modals/'; */

//
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import http from '@/helpers/apiCaller'
import routes from '@/routes/index.js'

class Full extends Component {
    constructor(props){
        super(props)
        http.initOnce(this.props)
    }
	renderRoutes(routes){
		let result = [];
		routes.forEach((route, index) => {
			result.push(
				<Route
					key={index}
					path={route.path}
					exact={route.exact || route.children ? true: false}
					name={route.name}
					component={route.component}
				/>
			);
			if(route.children){
				result.push(...this.renderRoutes(route.children))
			}
		});
		return result
	}
  render() {
    return (
		<ErrorBoundary>
			<div className="app">
				<Header {...this.props}/>
				<div className="app-body">
				<Sidebar {...this.props}/>
				<main className="main">
					<Breadcrumb/>
					<Container fluid>
					<Switch>
						{this.renderRoutes(routes)}
					</Switch>
					<ToastContainer />
					</Container>
				</main>
				<Aside/>
				</div>
				<Footer/>
			</div>
		</ErrorBoundary>
    );
  }
}

export default hot(module)(Full)
