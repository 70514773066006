class ActionReponse{
	static format(response, actionType){
		let result = {
			type: actionType,
			success: false,
            data: {},
            error: null
        };
        if(!response){
            result.error = "Request Failed"
            return result
        }

        result.success = [200,201].indexOf(response.status) !=-1  ? true: false

        if(result.success){
            result.data = response.data || `${response.status} ${response.statusText}`;
        }
        else{
            result.error = response.data || `${response.status} ${response.statusText}`;
        }
		return result;
	}
}
export default ActionReponse;
