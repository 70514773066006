export const message = {
    general: {
        success	: 'Thành Công !',
        warning	: 'Cảnh Báo',
        danger	: 'Nguy Hiểm ！',
        error	: 'Lỗi !'
    },
    project : {
        sample : {
            create: {
                success	: 'Thêm dữ liệu thành công',
                error	: 'Thêm dữ liệu không thành công'
            },
            update: {
                success	: 'Cập nhật dữ liệu thành công',
                error	: 'Cập nhật dữ liệu không thành công'
            },
            delete: {
                comfirm		: 'Bạn có chắc chắn xóa không?',
                description	: 'Dữ liệu sẽ không thể khôi phục sau khi xóa',
                success		: 'Xóa dữ liệu thành công',
                error		: 'Xóa dữ liệu không thành công'
            },
            comfirm: {
                false 	: "Hãy chọn lại!",
                success	: 'OK',
            },
            error			: 'エラーが発生しました！',
            comfirm_text	: 'OK',
            comfirm_delete	: {
                title				: 'Bạn chắc chắn muốn xóa?',
                text				: 'Dữ liệu sẽ không thể khôi phục sau khi xóa',
                confirm_button_text	: 'Có',
                cancel_button_text	: 'Không'
            },
            deleted	: {
                title	: 'Xóa Thành Công',
                text	: 'Dữ liệu đã được xóa'
            },
            extend	: {
                confirm 	: 'Hãy chọn lại!',
                description	: 'Không được chọn nhiều hơn một.',
            },
            no_result: 'Không có kết quả',
            changeClass: {
                error: {
                    empty 		: "Hãy chọn ít nhất một sinh viên",
                    moreClass	: "Không được chọn nhiều hơn một lớp."
                }
            },
            warningChange	: "Bạn muốn lưu những thay đổi ?",
            validate		: "Điểm sẽ không thay đổi"
        },

    }

}
