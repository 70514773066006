import React from 'react';
import {Translate} from 'react-redux-i18n';
import { Redirect } from 'react-router-dom'
import Dashboard from '@/views/Dashboard/';
import categories from './categories';
import apis from './apis';
import videos from './videos';

export default [
	{
		path: "/",
		component: () => <Redirect to='/dashboard' />,
		exact: true
    },
	{
		path: "/dashboard",
		title: <Translate value="sidebar.system.value" />,
		component: () => <Dashboard />,
		exact: true
    },
    ...categories,
    ...apis,
    ...videos,
]
