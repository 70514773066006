import * as types from '@/constants/actionType'
import BaseAction from './BaseActions'
import actionResponse from '@/helpers/actionResponse'

class CategoriesActions extends BaseAction{
    constructor(){
        super();
        this.actionResponse = actionResponse
        this.actions = {
            actFetchAll:   {
                method: 'get',
                url: `api/categories/:name`,
                type: types.LIST_API_BY_CATEGORIES_FETCH_ALL
            }
        }
    }

    actGetListCategory() {
        return async (dispatch) => {
            let result
            try {
                let response = await this.http.get('api/categories')
                result = this.actionResponse.format(response, types.LIST_CATEGORIES);
            }
            catch(e) {
                result = this.actionResponse.format(e.response, types.LIST_CATEGORIES);
            }
            dispatch(result)
        }
    }
}
export default new CategoriesActions;
