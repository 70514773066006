import React, { Component } from 'react';
import {Row, Col,} from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import videosActions from '@/actions/VideosActions';
import GridItem from './GridItem';
class Index extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {//auto load luôn khi load brower
        this.props.onLoad()
    }

    renderItem(videos){
        let result = [];
        if(typeof videos != "undefined") {
            for (let video of videos) {
                result.push(
                    <GridItem key={video.id} video={video}/>
                )
            }
        }

        return result;
    }

    render() {
        const {videos} = this.props
        const videosData = videos.data || {}
        let {list, next, previous, count} = videosData
        return (
        <div className="animated fadeIn">
            <div id="home-screen">
                <Row>
                    <Col lg={{size: 12}} xs={12} >
                        <div className="video-list">
                            <Row>
                                {this.renderItem(list)}
                            </Row>
                        </div>
                    </Col>
                </Row>
			</div>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        videos : state.videos,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoad: () => {
			dispatch(videosActions.actGetListVideo());
		},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
