import React, {Component} from 'react';
import {Button, Badge} from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {I18n} from 'react-redux-i18n'
import categoriesActions from '@/actions/CategoriesActions';
import ReactTable from "react-table"
import matchSorter from "match-sorter"

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name            : null,
            rowSelected     : {},
            objectName      : 'categories',
            title           : I18n.t('categories.title.detail'),
            filterable      : true,
            columns:[
                {
                    Header: I18n.t("categories.table.id"),
                    accessor: "id",
                    filterable: true,
                    show: false
                },
                {
                    Header: I18n.t("categories.table.thumbnail"),
                    accessor: "thumbnailUri",
                    width: 120,
                    Cell: row => this.customThumbnail(row),
                    className: "text-center"
                },
                {
                    Header: I18n.t("categories.table.name"),
                    accessor: "name",
                    filterable: true
                },
                {
                    Header: I18n.t("categories.table.description"),
                    accessor: "description",
                    Cell: row => this.customDescription(row),
                    filterable: true
                },
                {
                    Header: I18n.t("categories.table.provider"),
                    accessor: "provider",
                    filterable: true,
                    className: "text-center"
                },
                {
                    Header: I18n.t("categories.table.version"),
                    accessor: "version",
                    filterable: true,
                    className: "text-center"
                },

                {
                    Header: "Action",
                    Cell: row => this.customAction(row),
                    className: "text-center"
                },
            ]
        }
    }

    async componentDidMount() {//auto load luôn khi load brower
        await this.props.onLoad({name: this.props.match.params.name})
        await this.setState ({name: this.props.match.params.name})
    }

    async componentWillUpdate(nextProps) {
        let _name = nextProps.match.params.name;
        if(_name !== this.state.name ){
            await this.props.onLoad({name: _name})
            await this.setState ({name: _name})
        }

    }

    customThumbnail(row) {
        let firstChar = row.original.name.charAt(0).toUpperCase();
        let url = row.row.thumbnailUri ? `${process.env.WSO2_STORE}/api/am/store/v0.13${row.row.thumbnailUri}?w=100` : null;
        let hasThumb = {
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover'
        };

        return (
            <div className="image-post img-responsive img-thumbnail"
                onClick={()=>this.props.history.push(`/apis/${row.row.id}`)}
                style={url ? hasThumb : {backgoundColor: '#6b6b6b'}}>
                {url ? '' : firstChar}
            </div>
        )
    }

    customAction(row){
		return (
            <Button onClick={ () => this.props.history.push(`/apis/${row.row.id}`) } color="outline-primary"><i className="icon-eye"></i> { I18n.t("button.view") }</Button>
		)
    }

    customDescription(row){
        return row.value == null ? <Badge className="badge-grey">{I18n.t("categories.table.empty")}</Badge> : row.value
    }

    render() {
        const {data} = this.props
        const apilistData = data.list || {}
        return (
            <div className="animated fadeIn">
                {Array.isArray((data.list)) ?
                <ReactTable
                    data={apilistData}
                    defaultFilterMethod= {
                        function(filter, row) {
                            let data = matchSorter([String(row[filter.id])], filter.value);
                            if(data.length)
                                return true;
                            else
                                return false;
                        }
                    }
                    columns={this.state.columns}
                    defaultPageSize={10}
                    noDataText= {'There is no API in this category'}
                />
                : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data : state.categories,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoad: (params) => {
			dispatch(categoriesActions.actFetchAll(params));
		},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
