import React, {Component} from 'react';
import {Translate, Localize} from 'react-redux-i18n'
import Swal from 'sweetalert2'
var I18n = require('react-redux-i18n').I18n;

import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Badge,
  Button
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';

class Header extends Component {

  constructor() {
    super();
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  async logout (){
    Swal({
      title: I18n.t('logout.alertText'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: I18n.t('logout.confirm_button_text'),
      cancelButtonText:  I18n.t('logout.cancel_button_text')
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("token")
        this.props.history.push("/");
      }
    })
  }

  render() {
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <NavbarBrand onClick={() => this.props.history.push("/")}></NavbarBrand>
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <Nav className="d-md-down-none" navbar>
          {/* <Badge className="mr-1 pull-right" color="success">Quyền Hạn: Admintrator </Badge>    */}
        </Nav>
        <Nav className="ml-auto" navbar>

        </Nav>
      </header>
    );
  }
}

export default Header;
