export const project = {
	users: {
		value 					: "Người dùng",
		breadcrumbs: {
			index				: "Người Dùng",
			add					: "Thêm Người Dùng",
			edit 				: "Sửa Thông Tin Người Dùng",
    	},
    	index_title				: "DS Người Dùng",
		add_title				: "Thêm Người Dùng",
    	edit_title				: "Sửa Người Dùng",
		account					: 'Tên Đăng Nhập',
    	id						: "ID",
		code					: "Mã số",
		code_placeholder		: "Nhập mã số người dùng",
		name					: "Họ Tên",
		lastname				: "Tên",
		firstname				: "Họ",
		name_placeholder		: "Nhập tên người dùng",
		role					: "Quyền Hạn",
		role_placeholder		: "Chọn quyền hạn",
		password				: "Mật khẩu",
		password_confirmation	: "Nhập Lại Mật Khẩu",
		password_placeholder	: "Nhập mật khẩu",
		barcode					: "Mã barcode",
		barcode_placeholder		: "Nhập mã barcode nhân viên",
		save					: "Lưu lại",
		admin					: 'Quản Trị Viên'
	},
	roles: {
		breadcrumbs: {
		index: '出荷情報エクスポート',
		add: 'Thêm nhóm người dùng',
		edit: 'Sửa thông tin nhóm người dùng'
		},
		index_title: ' 出荷済商品の一覧',
		add_title: "Thêm nhóm người dùng",
		edit_title: "Sửa thông tin nhóm người dùng.",
		id: 'ID',
		display_name: 'Tên nhóm người dùng',
		display_name_placeholder: 'Nhập tên nhóm người dùng...',
		created_at: 'Ngày tạo',
		permissionIds: 'Các quyền truy cập',
		// button
			save: "Lưu lại",
			btn_add: 'Thêm',
			btn_remove: 'Xóa',
			btn_edit: 'Sửa',
	},
	dropzone: {
		status: {
			default	: "ここにExcelファイルをドロップまた選択してください",
			loading	: "Đang chuyển đổi dữ liệu..",
			finish	: "Hoàn thành !"
		}
	},
	toast : {
		warning : {
			canSave 	: 'Dữ liệu không được lưu',
		}
    },
    products : {
        value : "Sản Phẩm",
		breadcrumbs: {
			index : "Sản Phẩm",
			add	: "Thêm sản phẩm",
			edit : "Sửa sản phẩm",
        },
        title : {
            index : "DS Sản Phẩm",
            add	: "Thêm Sản Phẩm",
            edit : "Sửa Sản Phẩm",
        },
        table: {
            code : "Code sản phẩm",
            name : "Tên sản phẩm",
            barcode: "Barcode",
            note : "Ghi chú",
            created_at : "Ngày tạo"
        }
    },
    login: {
		title		: 'Đăng Nhập',
		description	: 'Logistics Management System | Hệ Thống Quản Lý Lấy Hàng ',
		button		: 'Đăng Nhập',
    },
	logout: {
        button				: 'Đăng Xuất',
        alertText			: 'Bạn có chắc chắn không?',
        confirm_button_text	: 'Có',
        cancel_button_text	: 'Không'
    },
    categories: {
        title : {
            detail : "Danh sách API",
        },
        table: {
            id 			: "ID",
			name 		: "API Name",
			description	: "Description",
            context		: "Context",
            provider	: "Provider",
            version 	: "Version",
			thumbnail	: "Thumbnail",
			empty		: "Empty"
        }
    }
}
