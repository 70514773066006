import * as types from '@/constants/actionType'
import _ from 'lodash'

class baseReducers {
    constructor(){
        this.typeToFunc = {}
        this.initialState = {
            list: {},
            item: {},
            data: {}
        } //state mặc định
        this.useStateDefault = true //sử dụng cấu trúc State dạng {list:{...}, item: {...}}
        /**
         * Khai báo tất cả các action được sử dụng trong reducer vào actionsAllow.
         */
        this.actionsAllow = [
        ]
        /**
         * sử dụng mapTypeToFunction dùng để kết nối giữa 1 action vào 1 hàm xử lý trong class
         */
        //this.mapTypeToFunction(types.USER_FETCH_ALL, this.fetchAll)
    }
    /**
     * Map type của action với function xử lý tương ứng
     * @param {*} type
     * @param {*} functionName
     */
    mapTypeToFunction(type, functionName){
        this.typeToFunc[type] = functionName
    }

    /**
     * Hàm private sử dụng để call các action tương ứng
     * @param {*} state
     * @param {*} action
     */
    fnCall(state, action){
        if(!state) state = this.initialState
        if(this.actionsAllow.indexOf(action.type) == -1) return state

        state.success   = action.success
        state.type      = action.type
        state.error     = action.error

        if(typeof this.typeToFunc[action.type] == "function"){
           return this.typeToFunc[action.type](state, action);
        }
        if(this.useStateDefault){
            return this.makeStateStruct(state, action)
        }
        else{
            state["data"] = action.data
            return Object.assign({}, state)
        }
    }

    makeStateStruct(state, action){
        if(!this.initialState['list']) this.initialState['list'] = {}
        if(!this.initialState['item']) this.initialState['item'] = {}
        if(action.type.indexOf("FETCH_ALL") != -1){ //xử lý mặc định set hàm FETCH ALL vào list
            state['list']   = this.initialState.list
            state.list = action.data
            return Object.assign({}, state)
        }
        else if(action.type.indexOf("FETCH") != -1){ //xử lý mặc định set hàm FETCH vào item
            state['item'] = this.initialState.item
            state.item = action
            return Object.assign({}, state)
        }
        else{
            state["data"] = action.data
            return Object.assign({}, state)
        }
    }



    export(){
        return this.fnCall.bind(this)
    }

    /**
     * Custom action....
     * functionName(state, action){....}
     */
}

export default baseReducers;
