import React from 'react';
import {Translate} from 'react-redux-i18n';

let items = [
    {
        name: <Translate value="sidebar.system.value" />,
        url: '/dashboard',
        icon: 'fa fa-diamond'
    },
    {
        name: <Translate value="sidebar.system.store_link" />,
        url:    process.env.WSO2_STORE + "/store",
        icon: 'fa fa-link'
    },
    {
        title   : true,
        name    : <Translate value="sidebar.categories.value" />,
    },
];

export default {items};



