import * as types from '@/constants/actionType'
import BaseAction from './BaseActions'
import actionResponse from '@/helpers/actionResponse'

class VideosActions extends BaseAction{
    constructor(){
        super();
        this.actionResponse = actionResponse
    }

    actGetListVideo() {
        return async (dispatch) => {
            let result
            try {
                let response = await this.http.get('api/videos')
                result = this.actionResponse.format(response, types.LIST_VIDEOS);
            }
            catch(e) {
                result = this.actionResponse.format(e.response, types.LIST_VIDEOS);
            }
            dispatch(result)
        }
    }
}
export default new VideosActions;
