import React, { Component } from 'react';
import {Row, Col,} from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import categoriesActions from '@/actions/CategoriesActions';
import GridItem from './GridItem';
class Dashboard extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {//auto load luôn khi load brower
        this.props.onLoad()
    }

    renderItem(categories){
        let result = [];
        if(typeof categories != "undefined") {
            for (let category of categories) {
                result.push(
                    <GridItem key={category.id} category={category}/>
                )
            }
        }

        return result;
    }

    render() {
        const {categories} = this.props
        const categoriesData = categories.data || {}
        let {list, next, previous, count} = categoriesData
        return (
        <div className="animated fadeIn">
            <div id="home-screen">
                <Row>
                    <Col lg={{size: 12}} xs={12} >
                        <Row>
                            {this.renderItem(list)}
                        </Row>
                    </Col>
                </Row>
			</div>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories : state.categories,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoad: () => {
			dispatch(categoriesActions.actGetListCategory());
		},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
