import * as types from '@/constants/actionType'
import baseReducers from './baseReducers'
import _ from 'lodash'
class categories extends baseReducers{
    constructor(){
        super()
        this.actionsAllow = [
            types.LIST_CATEGORIES,
            types.LIST_API_BY_CATEGORIES_FETCH_ALL,
        ]
        return this.export() //hàm cú pháp bắt buộc để export reducer
    }
}
export default new categories
