// Adapted from

import React from "react"
import PropTypes from "prop-types"

export default class BaseLayout extends React.Component {
    onFilterChange(e) {
        let {target: {value}} = e
        this.props.layoutActions.updateFilter(value)
    }

    render() {
        let { specSelectors, specActions, getComponent, layoutSelectors, ContentType } = this.props

        let info = specSelectors.info()
        let url = specSelectors.url()
        let basePath = specSelectors.basePath()
        let host = specSelectors.host()
        let securityDefinitions = specSelectors.securityDefinitions()
        let externalDocs = specSelectors.externalDocs()
        let schemes = specSelectors.schemes()
        let Info = getComponent("info")
        let Operations = getComponent("operations", true)
        // let HighlightCode = getComponent("HighlightCode", true)
        let Models = getComponent("Models", true)
        let AuthorizeBtn = getComponent("authorizeBtn", true)
        let Row = getComponent("Row")
        let Col = getComponent("Col")
        let Errors = getComponent("errors", true)

        let isLoading = specSelectors.loadingStatus() === "loading"
        let isFailed = specSelectors.loadingStatus() === "failed"
        let filter = layoutSelectors.currentFilter()

        let inputStyle = {}
        if(isFailed) inputStyle.color = "red"
        if(isLoading) inputStyle.color = "#aaa"

        const Schemes = getComponent("schemes")

        const isSpecEmpty = !specSelectors.specStr()

        if(isSpecEmpty) {
            return <h4></h4>
        }

        return (
            <div className='swagger-ui'>
                <div>
                    {/* <Errors/> */}
                    <Row>
                        <Col mobile={12} desktop={12} >
                            <Operations/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
