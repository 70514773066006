export const validate = {
    form: {
        required     : "Trường bắt buộc nhập",
		isNumber     : "Chỉ được phép nhập số",
		maxLength150 : "Chỉ được nhập tối đa 150 ký tự",
		maxLength50  : "Chỉ được nhập tối đa 50 ký tự",
		maxLength30  : "Chỉ được nhập tối đa 30 ký tự",
		maxLength20  : "Chỉ được nhập tối đa 20 ký tự",
		maxLength18  : "Chỉ được nhập tối đa 18 ký tự",
		maxLength15  : "Chỉ được nhập tối đa 15 ký tự",
		maxLength13  : "Chỉ được nhập tối đa 13 ký tự",
		maxLength11  : "Chỉ được nhập tối đa 11 ký tự",
		maxLength9   : "Chỉ được nhập tối đa 9 ký tự",
		maxLength8   : "Chỉ được nhập tối đa 8 ký tự",
		maxLength7   : "Chỉ được nhập tối đa 7 ký tự",
		maxLength4   : "Chỉ được nhập tối đa 4 ký tự",
		maxLength3   : "Chỉ được nhập tối đa 3 ký tự",
		maxLength5   : "Chỉ được nhập tối đa 5 ký tự",
		maxLength1   : "Chỉ được nhập tối đa 1 ký tự",
		//min length
		minLength10 : "Nhập ít nhất 9 ký tự",
		minLength9  : "Nhập ít nhất 9 ký tự",
		minLength8  : "Nhập ít nhất 8 ký tự",
		minLength6  : "Nhập ít nhất 6 ký tự",
		minLength5  : "Nhập ít nhất 5 ký tự",
		minLength4  : "Nhập ít nhất 4 ký tự",
		minLength3  : "Nhập ít nhất 3 ký tự",
        minLength2  : "Nhập ít nhất 2 ký tự",
		// min
		min0 		: "Lớn hơn 0",
		// max
		max10		: "Nhỏ hơn bằng 10",
		active      : "Đang hoạt động",
        inactive    : "Không hoạt động",
		all         : "全て",
		requiredDate: "Nhập sai ngày dd/mm/yyyy",
		checklist 	: {
			title_1 	: "BỘ LAO ĐỘNG TB & XÃ HỘI",
			title_2 	: "TRƯỜNG CĐN BÁCH KHOA HÀ NỘI",
			header	: {
				header_1: "BẢNG ĐIỂM THI LẦN",
				header_2: "HỌC KỲ",
				header_3: "Lớp",
				header_4: "Mã lớp học",
				theoretical : "Lý thuyết",
				practical 	: "Thực hành",
			},
			downHeader: {
				subject 	: 'Môn học: ',
				numberValue	: 'Số đơn vị học trình: ',
				lecture 	: 'Giáo viên: ...............................................',
				dataTest	: 'Ngày thi: ................................................'
			},
			tableFields: {
				stt 		: 'STT',
				codeStudent : 'Mã SV',
				fullName    : 'Họ và tên',
				birdDay     : 'Ngày sinh',
				score 		: 'Điểm',
				numberPaper : 'Số tờ',
				signture 	: 'Chữ ký SV',
				note 		: 'Ghi chú',
			},
			footer : {
				totalStudent 	: 'Tổng số sinh viên dự thi: ...........................',
				goodStudent 	: 'Số sinh viên đạt: ...........................................',
				totalPaper 		: 'Tổng số giấy thi: ...............................................',
				dateSend 		: 'Ngầy giáo viên nộp điểm: ............................',
				deadline		: 'Hạn cuối nộp điểm: ........................................',
				room 			: 'PHÒNG ĐÀO TẠO',
				cadres 			: 'CÁN BỘ CHẤM THI',
				dean 			: 'TRƯỞNG KHOA',
				cardSee1 		: 'CÁN BỘ COI THI 1',
				cardSee2 		: 'CÁN BỘ COI THI 2',
			},
		},
	},
}
