import React from 'react';
import {Translate} from 'react-redux-i18n';
import Detail from '@/views/Categories/Detail';
export default [
	{
        path: "/categories/:name",
        name: 'categories.show',
		title: <Translate value="sidebar.categories.detail" />,
		component: () => <Detail />,
		exact: false,
	}
]
