import React, { Component } from 'react';
import { Col} from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal,ModalManager,Effect} from 'react-dynamic-modal';
class GridItem extends Component {
    constructor(props) {
        super(props);
    }

    openModal(src){
        let srcC = `${src}?autoplay=1;rel=0&amp;showinfo=0`;
        ModalManager.open(<MyModal src={srcC} onRequestClose={() => true}/>);
    }

    render() {
        let {video} = this.props
        return (
            <Col md={4} className="form-group">
                <div className="theme-thumb">
                    <div className="theme-img">
                        <div className="aspect-holder">
                            <div className="aspect-16-9"></div>
                            <div className="element">
                                <div className="theme-img-layout">
                                    <a onClick={this.openModal.bind(this, video.src)}>
                                        <div className="theme-img-inner">
                                            <div className="template-preview-desc">
                                            </div>
                                            <div>
                                                <span className="template-preview-link">Preview</span>
                                            </div>
                                        </div>
                                        <img width="100%" height="100%" src={video.image} alt={video.title} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <span className="projname-span">{video.title}</span>
                    </div>
                </div>
            </Col>
        )
    }
}

const customStyles = {
    overlay: {
      position        : 'fixed',
      top             : 0,
      left            : 0,
      right           : 0,
      bottom          : 0,
      zIndex          : 99999999,
      overflow        : 'hidden',
      perspective     :  1300,
      backgroundColor : 'rgba(0, 0, 0, 0.3)'
    },

    content: {
      position                : 'relative',
      margin                  : '8% auto',
      width                   : '50%',
      border                  : '1px solid rgba(0, 0, 0, .2)',
      background              : '#fff',
      overflow                : 'auto',
      borderRadius            : '4px',
      outline                 : 'none',
      boxShadow               : '0 5px 10px rgba(0, 0, 0, .3)',
    }
};

class MyModal extends Component{
    render(){
       const { src } = this.props;
       return (
        <Modal
            effect={Effect.ScaleUp}
            onRequestClose={() => true}
            style={customStyles}
        >
            <div className="modal-body">
                <iframe
                    width="100%"
                    height="315"
                    src={src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn-outline-primary pull-right btn btn-secondary" onClick={ModalManager.close}>
                    <i className="icon-arrow-left"></i> Back
                </button>
            </div>
        </Modal>
       );
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch, props) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));
