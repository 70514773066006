import http from '@/helpers/apiCaller'
import actionResponse from '@/helpers/actionResponse'
import UrlPattern from 'url-pattern'

class BaseAction{
    constructor(){
        this.http           = http
        this.actionResponse = actionResponse
        this.actions        = {
            actCreate       :   {method: 'post', url: '',type: ''},
            actEdit         :   {method: 'put', url: '',type: ''},
            actDelete       :   {method: 'delete',  url: '',type: ''},
            actDeleteMulti  :   {method: 'delete',  url: '',type: ''},
            actFetch        :   {method: 'get',  url: '',type: ''},
            actFetchAll     :   {method: 'get',  url: '',type: ''},
            actImport       :   {method: 'post', url: '',type: ''},
            actAdmiss       :   {method: 'post', url: '',type: ''},
            actImportFees   :   {method: 'post', url: '',type: ''},
            actFilter       :   {method: 'get',  url: '',type: ''}
        }
        this.makeActionFromList();
    }
    makeActionFromList(){
        let listAction = Object.keys(this.actions);
        listAction.forEach((action) => {
            this[action] = (params) => {
                let {method, url, type} = this.actions[action]
                // console.log('vao request list action  params', params)
                // console.log('vao request list action  actions', this.actions[action])
                //build url từ partern khai báo trong actions
                var pattern = new UrlPattern(url, {
                    segmentNameCharset: 'a-zA-Z0-9_-'
                });
                url = pattern.stringify(params)
                return this.requestApi(method, url, params, type)
            }
        })
    }

    requestApi(method, url, params, actionType){
        return async (dispatch) => {
            let result
            try{
                // console.log('vao request API', params)
                let response = await this.http[method](url, params)
                // console.log('Response', response)
                result = this.actionResponse.format(response, actionType);
            }
            catch(e){
                result = this.actionResponse.format(e.response, actionType);
            }
            dispatch(result);
        }
    }
}
export default BaseAction;
