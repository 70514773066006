import axios from 'axios';
import * as config from '../constants/config.js'

class ApiCaller{
	static init(){
        let token = localStorage.getItem('token')
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    static initOnce(props){
        this.history = props.history
        this.location = props.location
        axios.interceptors.response.use(this.handleGlobal.bind(this), this.handleGlobalError.bind(this));
    }
    static handleGlobal(response){
        return response
    }
    static handleGlobalError(error){
        let status = error.response.status;
        if(status == 401){
            console.warn("Token expired")
            localStorage.removeItem("token");
            this.history.push('/login')
        }
        return Promise.reject(error)
    }
	static getAppUrl(){
		return process.env.API_URL
	}
	static async get(url, data = {}){
		this.init()
		let result = await axios.get(this.getAppUrl() + url,{
            params: data
        });
		return result
	}
	static async post(url, data){
		this.init()
		let result = await axios.post(this.getAppUrl() + url, data);
		return result
	}
	static async put(url, data){
		this.init()
		let result = await axios.put(this.getAppUrl() + url, data);
		return result
	}
	static async delete(url, data){
		this.init()
		let result = await axios.delete(this.getAppUrl() + url, {
            params: data
        });
		return result
	}
}

export default ApiCaller;
