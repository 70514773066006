import React, {Component} from 'react';
import SwaggerUIBundle  from 'swagger-custom-ui/dist/swagger-ui.js'
import SwaggerUIStandalonePreset from "swagger-custom-ui/dist/swagger-ui-standalone-preset.js"

import 'swagger-custom-ui/dist/swagger-ui.css';
import CustomLayout from './components/CustomLayout';
const DOM_ID = "swaggerContainer"

class SwaggerUI extends Component {
    componentDidMount() {
        let idAPI = this.props.idAPI
        console.log('idAPI', idAPI)
        const ui = SwaggerUIBundle({
            dom_id: `#${DOM_ID}`,
            url: `/api/apis/${idAPI}/swagger`,
            presets: [
                SwaggerUIBundle.presets.apis,
                SwaggerUIStandalonePreset
            ],
            plugins: [
                CustomLayoutPlugin
            ],
            layout: "CustomLayout"
        })
        window.x = ui

        // ui.initOAuth({
        //     clientId: "your-client-id",
        //     clientSecret: "your-client-secret-if-required",
        //     realm: "your-realms",
        //     appName: "your-app-name",
        //     scopeSeparator: " ",
        //     additionalQueryStringParams: {}
        // })
    }

    render() {
        return (
            <div id={DOM_ID} />
        );
    }
}

const CustomLayoutPlugin = {
    components: {
      CustomLayout: CustomLayout
    }
}

export default SwaggerUI;
