import {sidebar} from './sidebar';
import {message} from './message';
import {validate} from './validate';
import {project} from './project';

let obj = {
	message: message,
    sidebar: sidebar,
    validate: validate,
    table: {
        fromText	: 'Từ',
        toText		: 'đến',
        recordText	: 'bản ghi',
        ofText		: 'trong tổng số',
        previousText: '‹',
        nextText	: '›',
        toFirstText	: '«',
        toLastText	: '»',
        loadingText	: 'Đang tải dữ liệu...',
        noDataText	: 'Không có dữ liệu',
        pageText	: 'trang',
        rowsText	: 'hàng',
        showText	: 'Hiển thị',
        actionText	: 'Các mục đã chọn',
    },
	button: {
		create				: 'Thêm',
		create_manual		: 'Thêm Thủ Công',
		edit				: 'Sửa',
		delete				: 'Xóa',
		back				: 'Back',
		save				: 'Save',
		ok					: 'OK',
		cancel				: 'Hủy',
		import				: 'Thêm Từ Excel',
		remove_field		: 'Xóa field',
		action				: 'Tác Vụ',
		refresh				: 'Làm mới',
		skip				: 'Tiếp tục',
		print 				: "In ấn",
		statistics			: "Thống kê",
		print				: 'In danh sách',
		excel_export		: 'Xuất Danh Sách (Excel)',
        filter				: 'Lọc',
        view                : 'View'
    },
}

export const vi = Object.assign(obj, project);
