import React, { Component } from 'react';
import { Col} from 'reactstrap';
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

class GridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        };
    }

    MouseOverDiv() {
        this.setState({hovered: true});
    }

    MouseOutDiv() {
        this.setState({ hovered:false });
    }

    style(category) {
        if (this.state.hovered) {
            return {
                background: `#cc0000 url(${category.icon_hover}) center 30% no-repeat`
            }
        } else {
            return {
                background: `url(${category.icon_normal}) center 30% no-repeat rgb(255, 255, 255)`
            }
        }
    }

    render() {
        let {category} = this.props
        return (
            <Col md={3}
                className="custom-col" xs={6} key={category.id}
                onClick={() => this.props.history.push(`/categories/${category.id}`)}>
                <div className="iconBox"
                    onMouseOver={this.MouseOverDiv.bind(this)}
                    onMouseOut={this.MouseOutDiv.bind(this)}
                    style={this.style(category)}
                >
                    <h3>{ category.text }</h3>
                </div>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch, props) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));
