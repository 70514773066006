import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Full from './containers/Full/'
import Page404 from './views/Pages/Page404/'
import Page500 from './views/Pages/Page500/'

import configureStore from './helpers/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import ErrorBoundary from '@/components/ErrorBoundary'
const {store,persistor}  = configureStore();

export class Main extends Component {
    checkJWTToken(){
        try{
            let token = localStorage.getItem('token')
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            let decodedToken = JSON.parse(window.atob(base64));
            var dateNow = new Date();
            if(decodedToken.exp && decodedToken.exp < dateNow.getTime())
                return false
            return true
        }
        catch(e){
            return false;
        }
    }
	render(){
		return (
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter>
                            <Switch>
                            <Route exact path="/404" name="Page 404" component={Page404}/>
                            <Route exact path="/500" name="Page 500" component={Page500}/>
                            <Route path="/" render={(props) => (
                                <Full {...props} />
                            )}/>
                            </Switch>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
		)
	}
}
if (module.hot) {
	module.hot.accept();
    window.addEventListener('message', e => {
        if ('production' !== process.env.NODE_ENV) {

        }
    });
}
