import { createStore, applyMiddleware } from 'redux';
import appReducers from '../reducers/index';
import thunk from 'redux-thunk';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension';
import translationsObject from '@/langs/index'

export default function configureStore(initialState) {
    const store = createStore(
		appReducers,
		composeWithDevTools(
			applyMiddleware(thunk)
		)
	);
	const persistor = persistStore(store)
	/* const persistConfig = {
		key: 'root',
		storage,
	} */
    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers/index').default();
            //store.replaceReducer(persistConfig, nextRootReducer);
        });
	}
	//thêm file ngôn ngữ vào store
	syncTranslationWithStore(store)
	store.dispatch(loadTranslations(translationsObject));
	store.dispatch(setLocale(process.env.LANG_DEFAULT));
  return {store, persistor};
}