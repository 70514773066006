import React from 'react';
import {Translate} from 'react-redux-i18n';
import Detail from '@/views/Apis/Detail';
export default [
	{
        path: "/apis/:id",
        name: 'apis.show',
		title: <Translate value="sidebar.apis.value" />,
		component: () => <Detail />,
		exact: false,
	}
]
