import * as types from '@/constants/actionType'
import BaseAction from './BaseActions'
import actionResponse from '@/helpers/actionResponse'

class ApisActions extends BaseAction{
    constructor(){
        super();
        this.actionResponse = actionResponse
    }

    actGetDetailAPI() {
        return async (dispatch) => {
            let result
            try {
                let response = await this.http.get(`api${location.pathname}`)
                result = this.actionResponse.format(response, types.DETAIL_APIS);
            }
            catch(e) {
                result = this.actionResponse.format(e.response, types.DETAIL_APIS);
            }
            dispatch(result)
        }
    }
}
export default new ApisActions;
