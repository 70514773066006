import React from 'react';
import ReactDOM from 'react-dom';
import {Main} from './main'

// Styles
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import '../scss/style.scss'
import '../scss/core/_dropdown-menu-right.scss'
import 'react-widgets/dist/css/react-widgets.css';
import 'bootstrap-daterangepicker/daterangepicker.css'

ReactDOM.render((
	<Main />
), document.getElementById('root'));
